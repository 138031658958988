
  import { defineComponent, computed } from "vue"
  import IconLabel from "@/components/shared/IconLabel.vue"
  import { ResourceObject } from "@/models/jsonapi"
  import { Address } from "@/models/addresses"
  import { fullSalute } from "@/utils/dataExtractors"
  import { removePolishCompanyType } from "@/utils/helper"

  interface Props {
    address: ResourceObject<string, Address>,
  }

  export default defineComponent({
    name: 'Address',
    props: {
      address: {
        type: Object,
        required: true
      },
    },
    components: { IconLabel },
    setup(props: Props) {
      const defaultPhone = computed(() => {
        const phones = props.address.attributes?.phones
        if (!phones || phones.length === 0) return 'Unbekannt'
        const defaultPhones = phones.filter(p => p.default)
        return defaultPhones.length > 0 ? defaultPhones[0].number : phones[0].number
      })

      const defaultEmail = computed(() => {
        const emails = props.address.attributes?.emails
        if (!emails || emails.length === 0) return 'Unbekannt'
        const defaultEmails = emails?.filter(e => e.default)
        return defaultEmails.length > 0 ? defaultEmails[0].email : emails[0].email
      })

      const location = (cr) => {
        const zipCode = cr.zipCode ?? ''
        const city = cr.city ?? ''
        const location = `${zipCode} ${city}`.trim()
        return (location.length > 0) ? location : 'Unbekannt'
      }

      const street = (cr) => {
        const streetName = cr.street ?? ''
        const houseNumber = cr.houseNumber ?? ''
        const street = `${streetName} ${houseNumber}`.trim()
        return (street.length > 0) ? street : 'Unbekannt'
      }

      return {
        defaultPhone,
        defaultEmail,
        street,
        location,
        fullSalute,
        removePolishCompanyType
      }
    }
  })
