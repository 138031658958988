
import { defineComponent } from 'vue'

export default defineComponent({
  props: {
    icon: {
      type: Array,
      required: true,
    },
    label: {
      type: String,
      required: true,
    },
    iconProps: {
      type: Object,
      required: false,
    },
    labelProps: {
      type: Object,
      required: false,
    }
  },
})
