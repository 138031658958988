
import { defineComponent, ref, watch } from "vue"
import BillAddress from "@/components/shared/BillAddress.vue"

export default defineComponent({
  name: "AddressSelector",
  components: { BillAddress },
  props: {
    addresses: {
      type: Array,
      required: true
    }
  },
  setup(props, { emit }) {
    const value = ref(props.addresses[0].attributes.sysId)

    watch(value, (updatedValue) => {
      emit('input', updatedValue)
    }, { immediate: true })

    return {
      value
    }
  }
})
